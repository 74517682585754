import React from "react";

interface Props {
    width?: string;
    height?: string;
}

function Spacer({ width, height }: Props) {
    return (
        <svg className="breakIcon" width={width} height={height} viewBox="0 0 106 12">
            <g transform="translate(-587 -7284) translate(587 7284)">
                <rect width="12" height="12" x="47" fill="#F69833" rx="2"/>
                <circle cx="87" cy="6" r="6" fill="#FDBD39"/>
                <circle cx="19" cy="6" r="6" fill="#FDBD39"/>
                <path fill="#EE6723" d="M70 3L73 6 70 9 67 6zM36 3L39 6 36 9 33 6z"/>
                <circle cx="2" cy="6" r="2" fill="#C8CF2D"/>
                <circle cx="104" cy="6" r="2" fill="#C8CF2D"/>
            </g>
        </svg>
    );
}

Spacer.defaultProps = {
    width: 106,
    height: 12
};

export default Spacer;
