import React from "react";

import "./remark.css";

interface Props {
  remark: JSX.Element[];
}

const Remark = ({ remark }: Props) => (
    <blockquote className="remark">
        <h3>"{remark}"</h3>
    </blockquote>
);

export default Remark;
