import React from "react";

import "./gitCard.css";

interface Props {
    image: string;
    title: string;
    description: string;
    url: string;
}

const GitCard = ({ image, title, description, url }: Props) => (
    <a className="gitCard noSelect" href={url} target="_blank" rel="noopener">
        <div className="info">
            <h4 className="title ellipsis">{title}</h4>
            <span className="description textBlock blogText ellipsis">{description}</span>
            <span className="gitLabel">github.com</span>
        </div>
        <div className="cardImageContainer">
            <img
                className="cardImage"
                src={image}/>
        </div>
    </a>
);

export default GitCard;
