import React from "react";
import Prism from "../../prism/prism";

import "./code.css";
import "../../prism/extras";

interface Props {
    content: string;
    lang: string;
    asyncHighlight: boolean;
    style: object;
}

interface State {
    lines: Token[][] | string[][] | string[];
}

interface Token {
    type: string;
    content: string;
}

function groupTokensByLine(tokens: string[]) {
    let group: string[] = [];
    const groups = [group];

    for (const token of tokens) {
        if (typeof token === "string" && token.includes("\n")) {
            const lines = token.split("\n");

            group.push(lines[0]);

            for (let i = 1; i < lines.length - 1; i++) {
                groups.push([lines[i]]);
            }

            group = [lines[lines.length - 1]];
            groups.push(group);
        } else {
            group.push(token);
        }
    }

    return groups;
}

function tokenize(content: string, lang: string, isAsync: boolean) {
    if (!isAsync) {
        return Promise.resolve(Prism.tokenizeFlat(content, Prism.languages[lang]));
    }

    return new Promise((resolve, reject) => {
        try {
            Prism.tokenizeFlat(content, Prism.languages[lang], true, (tokens: Token[]) => {
                resolve(tokens);
            });
        } catch (error) {
            reject(error);
        }
    });
}

function isLanguageSupported(language: string) {
    return language && !!Prism.languages[language];
}

class Code extends React.Component<Props, State> {
    state = {
        lines: []
    };
    highlight(props: Props) {
        const { content, lang, asyncHighlight = true } = props;

        if (isLanguageSupported(lang)) {
            tokenize(content, lang, asyncHighlight).then(tokens => {
                this.setState({ lines: groupTokensByLine(tokens) });
            }).catch(() => {
                this.setState({ lines: content ? content.split("\n") : [] });
            });
        } else {
            this.setState({ lines: content ? content.split("\n") : [] });
        }
    }

    componentDidMount() {
        this.highlight(this.props as Props);
    }

    componentWillReceiveProps(nextProps: Props) {
        this.highlight(nextProps);
    }

    render() {
        const { lang, style, content } = this.props as Props;
        const { lines = [] } = this.state as State;

        return !!content && (
            <div className="codePanel">
                {/* <div className="codePanelGutter"/> */}
                <div className="snippet">
                    <pre className="code lineIndicator">
                        <code className={`language-${lang}`} style={style}>
                            {lines.map((line: Token[] | string[] | string, idx: number) => (
                                <span key={idx} className="lineWrapper">
                                    {/* <span className="lineno"></span> */}
                                    <pre className="line">
                                        {Array.isArray(line) ? line.map((token: Token | string, index: number) => (
                                            typeof token === "string" ? token : <span key={index} className={`token ${token.type}`}>{token.content}</span>
                                        )) : line}
                                    </pre>
                                </span>
                            ))}
                        </code>
                    </pre>
                </div>
            </div>
        );
    }
}

export default Code;
