import React from "react";
import classname from "../../utils/classname";

import "./externalLink.css";

interface Props {
    label: string;
    url: string;
    class?: string;
}

const ExternalLink = ({ label, url, class: cssClass }: Props) => (
    <div className={classname("externalLink", cssClass)}>
        🔗
        <a className="link" href={url} target="_blank" rel="noopener">{label}</a>
    </div>
);

export default ExternalLink;
