import React, {useEffect, useState} from "react"
import { TableOfContentsEntry } from "../../types/postTypes"
import "./TableOfContents.css"

interface Props {
  items: TableOfContentsEntry[] | undefined;
  h2Refs: React.RefObject<HTMLHeadingElement>[] | []
}

const TOP_SCROLL_OFFSET = 120

const Toc = ({
  items,
  h2Refs,
}: Props) => {
  if (!items) {
    return <></>
  }

  const [active, setActive] = useState<TableOfContentsEntry[]>([])
  const [prevActive, setPrevActive] = useState<TableOfContentsEntry | null>(null)

  const ref = React.createRef<HTMLDivElement>()

  const onItemClick = (e: React.SyntheticEvent, key: string) => {
    e.preventDefault()

    const foundCurrent = h2Refs.find((_ref) => !!_ref.current && _ref.current.id.replace("header-", "") === key)
    const currentHeaderEl = !!foundCurrent && foundCurrent.current
    const postTitle = document.querySelector('#header-title')

    if (currentHeaderEl) {
      const y = currentHeaderEl.getBoundingClientRect().top + window.pageYOffset - TOP_SCROLL_OFFSET

      window.scrollTo({top: y, behavior: 'smooth'})
    } else if (postTitle) {
      const y = postTitle.getBoundingClientRect().top + window.pageYOffset - TOP_SCROLL_OFFSET

      window.scrollTo({top: y, behavior: 'smooth'})
    }

  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([e]) => {
        if (e.intersectionRatio < 1) {
          setActive([])
          setPrevActive(null)
        }
      },
      {threshold: [1]}
    )

    if (ref.current) {
      observer.observe(ref.current)
    }

    const setCurrent: IntersectionObserverCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach(({isIntersecting, intersectionRatio, target}) => {
        if (isIntersecting && intersectionRatio >= 1) {
          const obj: TableOfContentsEntry | undefined = items.find(({key}) => key === target.id.replace("header-", ""))

          if (!!obj) {
            setActive([
              ...active,
              ...[obj]
            ])
            setPrevActive(obj)
          }
        } else if (active.some((item: TableOfContentsEntry) => {
          return !!item && !!prevActive && item.key !== prevActive.key
        })) {
          const newActive = active.filter(({key}) => key !== target.id.replace("header-", ""))
          setActive(newActive)
          setPrevActive(null)
        }
      })
    }

    const observeHtags = new IntersectionObserver(setCurrent, {
      rootMargin: '0px',
      threshold: 1.0
    })

    h2Refs.forEach((_ref) => !!_ref.current && observeHtags.observe(_ref.current))
  }, [])

  return (Array.isArray(items) && items.length > 0) ? (
    <div className="tocRails">
      <div ref={ref} className="toc">
        {
          items.map(({value, key}) => (
            <a
              key={`toc-anchor-${key}`}
              className={`tocItem${active.some((item: TableOfContentsEntry) => !!item && item.key === key) ? ' current' : ''}`}
              href={`#header-${key}`}
              onClick={(e) => onItemClick(e, key)}
            >
              <span className="tocItemText">{value}</span>
            </a>
          ))
        }
      </div>
    </div>
  ) : <></>
}

export default Toc;