import React from "react";

import "./image.css";

interface Props {
    src: string;
    srcSet?: string;
    sizes?: string;
    alt?: string;
    caption?: string;
    className?: string;
    style?: React.CSSProperties;
}

const Image = ({
    src,
    srcSet,
    sizes,
    alt = "",
    caption = "",
    className: cssClass = "",
    style
}: Props) => (
    <figure className={`image ${cssClass}`} style={style}>
        <img src={src} style={style} srcSet={srcSet} sizes={sizes} alt={alt}/>
        <figcaption className="caption">
            {caption}
        </figcaption>
    </figure>
);

export default Image;
