import React from "react";

import "./videoMP4.css";

interface Props {
    image: string;
    video: string;
    isLoop: boolean;
}

const VideoMP4 = ({ image, video, isLoop }: Props) => {
    const [_file, id, extension] = video.split("-")
    return (
        <div className="video postVideo">
            <video poster={image} src={`https://cdn.sanity.io/files/${process.env.PROJECT_ID}/production/${id}.${extension}`} playsInline muted autoPlay loop={isLoop}>
                <p>Your browser doesn't support HTML video.</p>
            </video>
        </div>
    )
};

export default VideoMP4;
