import React from "react";

import FacebookIcon from "../FacebookIcon";
import TwitterIcon from "../TwitterIcon";
import LinkedinIcon from "../LinkedinIcon";

import "./shareLinks.css";

interface Props {
    url: string;
    title?: string;
}

const ICON_COLOR = "var(--gandalf)";

const ShareLinks = ({
    url,
    title
}: Props) => (
    <div className="shareLinks">
        <a
            target="_blank"
            rel="noopener"
            href={`https://twitter.com/share?url=${url}&text=${title}`}>
            <TwitterIcon color={ICON_COLOR}/>
        </a>
        <a
            target="_blank"
            rel="noopener"
            href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}`}>
            <LinkedinIcon/>
        </a>
        <a
            target="_blank"
            rel="noopener"
            href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}>
            <FacebookIcon color={ICON_COLOR}/>
        </a>
    </div>
);

export default ShareLinks;
