import React from "react";

import "./faq.css";

import { TextBlock, FaqBlockEntry } from "../../types/postTypes"

interface Props {
    title?: string;
    content?: FaqBlockEntry[];
    getParsedTextBlock: (args: TextBlock) => JSX.Element | undefined;
}

const Faq = ({ title, content, getParsedTextBlock }: Props) => {
    if (!Array.isArray(content)) {
        return <></>
    }

    return (
        <section>
            {
                !!title && (
                    <h2 className="faqTitle">{title}</h2>
                )
            }
            {content.map((group) =>
                !!group.title
                && Array.isArray(group.text)
                && (
                    <div key={`faq-pane-${group._key}`} className="faqPanel">
                        <h3 className="faqPanelTitle">{group.title}</h3>

                        <div className="faqPanelDesc">{getParsedTextBlock(group.text[0])}</div>
                    </div>
                )
            )}
        </section>
    )
};

export default Faq;