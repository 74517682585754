import React from "react";

interface Props {
    label: string;
    url: string;
    class?: string;
}

const Button = ({ label, url }: Props) => (
    <a className="button" href={url}>{label}</a>
);

export default Button;
