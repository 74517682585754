import React from "react";

import "./endIcon.css";

interface Props {
    color: string;
    width: string;
    height: string;
}

interface IconProps {
    rotate?: boolean;
    width?: string;
    height?: string;
}

function Icon({
    rotate,
    width,
    height
}: IconProps) {
    return (
        <svg className={`${rotate ? "rotate" : ""}`} width={width} height={height} viewBox="0 0 51 10">
            <g fill="none" fillRule="evenodd">
                <g transform="translate(-641 -7590) translate(641 7584)">
                    <g transform="translate(0 6)">
                        <rect width="10" height="10" x="28" fill="#d8d8d8" rx="2"/>
                        <path fill="#d8d8d8" d="M16 2L19 5 16 8 13 5z"/>
                        <circle cx="2" cy="5" r="2" fill="#d8d8d8"/>
                        <circle cx="49" cy="5" r="2" fill="#d8d8d8"/>
                    </g>
                </g>
            </g>
        </svg>
    );
}

function EndIcon({ width, height }: Props) {
    return (
        <div className="end">
            <Icon width={width} height={height}/>
            <span className="label">Fin</span>
            <Icon rotate width={width} height={height}/>
        </div>
    );
}

EndIcon.defaultProps = {
    color: "var(--gandalf)",
    width: 51,
    height: 10
};

export default EndIcon;
