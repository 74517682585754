import React from "react";

import "./quote.css";

interface Props {
    quote: string;
    title?: JSX.Element;
    owner: string;
}

const Quote = ({ quote, title, owner }: Props) => (
    <blockquote className="quoteWrapper textBlock blogText">
        <p className="quote">{quote}</p>
        {
            !!owner && (
                <span className="owner">
                    <strong>{owner}{!!title && ","} </strong> {!!title && title}
                </span>
            )
        }
        
    </blockquote>
);

export default Quote;
