import React, { useContext, useEffect } from "react";
import { graphql, Link } from "gatsby";

import Seo from "../../components/Seo";
import Layout from "../../components/Layout";
import Author from "../../components/Author";
import AuthorLine from "../../components/AuthorLine";
import Card from "../../components/Card";
import EndIcon from "../../components/EndIcon";
import Image from "../../components/Image";
import TableOfContents from "../../components/TableOfContents";
import PostInfo from "../../components/PostInfo";
import Quote from "../../components/Quote";
import Remark from "../../components/Remark";
import ShareLinks from "../../components/ShareLinks";
import Spacer from "../../components/Spacer";
import Tag from "../../components/Tag";
import ExternalLink from "../../components/ExternalLink";
import { TwitterTweetEmbed } from "react-twitter-embed";
import classname from "../../utils/classname";
import Button from "../../components/Button";
import GitCard from "../../components/GitCard";
import VideoMP4 from "../../components/VideoMP4";
import Faq from "../../components/Faq";
import Code from "../../components/Code";
import {
    TextBlockElement,
    TextBlock,
    ImageBlock,
    ImageWithSizeBlock,
    ImageWithAltBlock,
    RemarkBlock,
    YoutubeBlock,
    QuoteBlock,
    LinkBlock,
    TwitterBlock,
    GitCardBlock,
    VideoMP4Block,
    FaqBlock,
    FaqBlockEntry,
    CodeBlock,
    ImageWithCaptionBlock,
    SpacerBlock,
    Props
} from "../../types/postTypes";
import {
    LinkedData
} from "../../types/types";
import { MixpanelContext, trackMixpanelOnScroll } from "../../tracking";
import UserDataContext from "../../userDataContext";
import ScrollMarker from "../../components/ScrollMarker";

import "./post.css";

export default function Component({ data }: Props) {
    const mixpanel = useContext(MixpanelContext);

    React.useEffect(() => {
        window.scrollTo(0, 0);
        mixpanel.track("Visited landing page", {
            // path: window.location.href
            Path: window.location.pathname
        });
    }, []);

    const {
        slug: {
            current: postSlug
        },
        title,
        _rawBody: blocks,
        mainImage,
        description,
        tableOfContents,
        tag,
        publishedAt,
        updatedAt,
        author
    } = data.sanityPost;

    const {
        image: authorImage,
        name: authorName,
        username: authorUsername,
        color: authorColor,
        email: authorEmail,
        twitter: authorTwitter
    } = author || {};

    const { siteUrl } = data.site.siteMetadata;
    const { edges: images } = data.allSanityImageAsset;
    let listElementIndex = 1;
    const h2Refs: React.RefObject<HTMLHeadingElement>[] = [];

    function getParsedBlockElements({
        children, style, markDefs, listItem, _key: parentKey
    }: TextBlock) {
        const blockElements = children?.filter(({ text }) => text).map(
            ({ text, marks, _key: blockElementKey }: TextBlockElement) => {
                const blockStyles: string[] = [];

                let parsedBlockElement;

                switch (style) {
                    case "normal":
                        parsedBlockElement = <span>{text}</span>;
                        break;
                    case "h1":
                        parsedBlockElement = <h1>{text}</h1>;
                        break;
                    case "h2":
                        const h2Ref = React.createRef<HTMLHeadingElement>();
                        parsedBlockElement = <h2 ref={h2Ref} id={`header-${parentKey}`}>{text}</h2>;
                        h2Refs.push(h2Ref);

                        break;
                    case "h3":
                        parsedBlockElement = <h3>{text}</h3>;
                        break;
                    case "h4":
                        parsedBlockElement = <h4>{text}</h4>;
                        break;
                    default:
                        parsedBlockElement = <span>{text}</span>;
                }

                if (listItem === "bullet") {
                    blockStyles.push("bullet");
                }

                let blockChildren: JSX.Element | string = text;
                if (marks.length) {
                    marks.forEach(mark => {
                        if (mark === "em") {
                            blockStyles.push("italic");
                        } else if (mark === "strong") {
                            blockChildren = <strong>{text}</strong>;
                        } else if (mark === "code") {
                            blockChildren = <code className="inlineCode">{text}</code>;
                        } else {
                            const markDef = markDefs.find(({ _key: key }) => key === mark);
                            if (markDef?._type === "link") {
                                blockStyles.push("postLink");
                                blockChildren = <a target={markDef.href?.includes("blog.zeplin.io") ? "_self" : "_blank"} href={markDef.href}>{text}</a>;
                            }
                        }
                    });
                }

                return React.cloneElement(parsedBlockElement, {
                    className: blockStyles.join(" "),
                    key: blockElementKey,
                    children: blockChildren
                });
            });

        return blockElements;
    }

    function getParsedTextBlock({
        children,
        style,
        markDefs,
        listItem,
        _key
    }: TextBlock): JSX.Element | undefined {
        const blockElements = getParsedBlockElements({
            children, style, markDefs, listItem, _key
        } as TextBlock);

        if (blockElements && blockElements.length) {
            if (listItem === "number") {
                const listElementBlock = (
                    <div className="blogText textBlock numberedListBlock">
                        <span className="listIndex">{listElementIndex}.</span>
                        {blockElements}
                    </div>
                );

                listElementIndex += 1;

                return listElementBlock;
            }

            if (listElementIndex > 1) {
                listElementIndex = 1; // reset list element index
            }

            return <div key={_key} className={classname("blogText", "textBlock", `${style}`, { listBlock: listItem === "bullet" })}>{blockElements}</div>;
        }
    }

    function getParsedImageBlock(block: ImageBlock): JSX.Element {
        const { _ref: id } = block.asset;
        const { _key: blockKey } = block;
        const asset = images.find(({ node: { _id } }) => _id === id);

        return (
            <img
                key={blockKey}
                className="postImage"
                src={asset?.node.gatsbyImageData.images.fallback.src}
                srcSet={asset?.node.gatsbyImageData.images.fallback.srcSet}
                sizes={asset?.node.gatsbyImageData.images.fallback.sizes} />
        );
    }

    function getParsedImageWithSizeBlock(block: ImageWithSizeBlock): JSX.Element {
        const { _ref: id } = block.imageWithAlt.image.asset;
        const { _key: blockKey } = block;
        const asset = images.find(({ node: { _id } }) => _id === id);

        return (
            <img
                key={blockKey}
                className="postImage"
                style={{ width: block.width }}
                src={`${asset?.node.gatsbyImageData.images.fallback.src}`}
                srcSet={asset?.node.gatsbyImageData.images.fallback.srcSet}
                sizes={asset?.node.gatsbyImageData.images.fallback.sizes} />
        );
    }

    function getParsedImageWithAltBlock(block: ImageWithAltBlock): JSX.Element {
        if (!(block.image && typeof block.image.asset === "object")) { return <></>; }

        const { _ref: id } = block.image.asset;
        const { _key: blockKey } = block;
        const asset = images.find(({ node: { _id } }) => _id === id);

        return (
            <Image
                key={blockKey}
                className={classname("postImage", { wide: block.wide })}
                style={block.width ? { width: block.width } : undefined}
                alt={block.alt || ""}
                src={`${asset?.node.gatsbyImageData.images.fallback.src}`}
                srcSet={asset?.node.gatsbyImageData.images.fallback.srcSet}
                sizes={asset?.node.gatsbyImageData.images.fallback.sizes} />
        );
    }

    function getParsedRemarkBlock({ remark, _key }: RemarkBlock): JSX.Element {
        return !!remark && <Remark key={_key} remark={getParsedBlockElements(remark[0])} />;
    }

    function getParsedSpacerBlock({ _key }: SpacerBlock): JSX.Element {
        return <Spacer key={_key} />;
    }

    function getParsedYoutubeBlock({ url, _key }: YoutubeBlock): JSX.Element {
        let youtubeVideoUrl: any;

        try {
            youtubeVideoUrl = new URL(url);
        } catch {
            return <></>;
        }

        const urlParams = new URLSearchParams(youtubeVideoUrl.search);
        const id = urlParams.get("v");
        const embedUrl = `https://www.youtube.com/embed/${id}`;

        return (
            <div key={_key} className="youtube">
                <iframe
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    title="video"
                    allowFullScreen
                    src={embedUrl} />
            </div>
        );
    }

    function getParsedQuoteBlock({ quoteText, name, title: quoteTitle, _key }: QuoteBlock): JSX.Element {
        return (
            <Quote
                key={_key}
                quote={quoteText}
                owner={name}
                title={quoteTitle ? getParsedTextBlock(quoteTitle[0]) : undefined} />
        );
    }

    function getParsedLinkBlock({ label, url, _key }: LinkBlock): JSX.Element {
        return !!label && !!url
            ? (
                <ExternalLink
                    key={_key}
                    class="blogText textBlock"
                    label={label}
                    url={url} />
            )
            : <></>;
    }

    function getParsedActionButtonBlock({ label, url, _key }: LinkBlock): JSX.Element {
        return !!label && !!url
            ? (
                <div key={_key} className="buttonBlock">
                    <Button
                        class="blogText textBlock"
                        label={label}
                        url={url} />
                </div>
            )
            : <></>;
    }

    function getParsedTwitterBlock({ id, _key }: TwitterBlock): JSX.Element {
        return (id ? <TwitterTweetEmbed key={_key} tweetId={id} options={{ conversation: "none" }} /> : <></>);
    }

    function getParsedGitCardBlock({
        image,
        title: gitCardTitle,
        description: gitCardDescription,
        url,
        _key
    }: GitCardBlock): JSX.Element {
        let asset;
        if (image) {
            const { _ref: id } = image.asset;
            asset = images.find(({ node: { _id } }) => _id === id);
        }

        return gitCardTitle
            ? (
                <GitCard
                    key={_key}
                    image={`${asset?.node.gatsbyImageData.images.fallback.src}`}
                    title={gitCardTitle}
                    description={gitCardDescription}
                    url={url} />
            )
            : <></>;
    }

    function getParsedVideoMP4Block({
        image,
        video,
        isLoop,
        _key
    }: VideoMP4Block): JSX.Element {
        let asset;
        if (image) {
            const { _ref: id } = image.asset;
            asset = images.find(({ node: { _id } }) => _id === id);
        }

        return !!video && !!video.asset && (
            <VideoMP4
                key={_key}
                image={`${image ? asset?.node.gatsbyImageData.images.fallback.src : "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="}`}
                video={video.asset._ref}
                isLoop={isLoop} />
        );
    }

    function getParsedFaqBlock({
        title: faqTitle,
        content: faqChidlren,
        _key
    }: FaqBlock): JSX.Element {
        return (
            <Faq
                key={_key}
                title={faqTitle}
                content={faqChidlren}
                getParsedTextBlock={getParsedTextBlock} />
        );
    }

    function getParsedCodeBlock({ language, code }: CodeBlock): JSX.Element {
        return <Code lang={language} content={code} asyncHighlight={false} style={{}} />;
    }

    function getParsedImageWithCaptionBlock(block: ImageWithCaptionBlock): JSX.Element {
        const { _key: blockKey } = block;

        if (!block.imageWithAlt) {
            return <></>;
        }

        let imageWithCaption;
        if (block.imageWithAlt.image && block.imageWithAlt.image.asset) {
            const { _ref: id } = block.imageWithAlt.image.asset;
            imageWithCaption = images.find(({ node: { _id } }) => _id === id);
        }

        return (
            <Image
                key={blockKey}
                className={classname("postImage", { inline: block.inline, rightAligned: block.inlineRightAlignment, wide: block.imageWithAlt.wide })}
                style={block.imageWithAlt.width ? { width: block.imageWithAlt.width } : undefined}
                src={imageWithCaption?.node.gatsbyImageData.images.fallback.src || ""}
                srcSet={imageWithCaption?.node.gatsbyImageData.images.fallback.srcSet}
                sizes={imageWithCaption?.node.gatsbyImageData.images.fallback.sizes}
                alt={block.imageWithAlt.alt}
                caption={block.caption} />
        );
    }

    const elems: JSX.Element[] = [];

    blocks?.forEach(element => {
        if (element) {
            const { _type: type } = element;

            switch (type) {
                case "block": {
                    const parsedTextBlock = getParsedTextBlock(element);
                    if (parsedTextBlock) {
                        elems.push(parsedTextBlock);
                    }
                    break;
                }
                case "image":
                    elems.push(getParsedImageBlock(element));
                    break;
                case "imageWithSize":
                    elems.push(getParsedImageWithSizeBlock(element));
                    break;
                case "imageWithAlt":
                    elems.push(getParsedImageWithAltBlock(element));
                    break;
                case "imageWithCaption": {
                    if (element.inline) {
                        elems.push(getParsedImageWithCaptionBlock(element));
                    } else {
                        elems.push(getParsedImageWithCaptionBlock(element));
                    }
                    break;
                }
                case "remark":
                    elems.push(getParsedRemarkBlock(element));
                    break;
                case "spacer":
                    elems.push(getParsedSpacerBlock(element));
                    break;
                case "youtube":
                    elems.push(getParsedYoutubeBlock(element));
                    break;
                case "quote":
                    elems.push(getParsedQuoteBlock(element));
                    break;
                case "link":
                    elems.push(getParsedLinkBlock(element));
                    break;
                case "twitter":
                    elems.push(getParsedTwitterBlock(element));
                    break;
                case "actionButton":
                    elems.push(getParsedActionButtonBlock(element));
                    break;
                case "gitCard":
                    elems.push(getParsedGitCardBlock(element));
                    break;
                case "videoMP4":
                    elems.push(getParsedVideoMP4Block(element));
                    break;
                case "faq":
                    elems.push(getParsedFaqBlock(element));
                    break;
                case "code":
                    elems.push(getParsedCodeBlock(element));
                    break;
            }
        }
    });

    const _heroEndMarkerRef = React.useRef<HTMLDivElement>(null);

    const mainLinkedData: LinkedData = {
        type: "NewsArticle",
        datePublished: publishedAt,
        dateModified: updatedAt,
        headline: title,
        author: {
            name: authorName,
            alternateName: authorUsername,
            url: authorTwitter
        },
        image: mainImage?.asset.gatsbyImageData.images.fallback.src
    };

    const userData = useContext(UserDataContext);

    useEffect(() => {
        trackMixpanelOnScroll(userData.data);
    }, []);

    const faqBlocks = (blocks || []).filter(element => element && element._type == "faq");

    if (faqBlocks.length > 0) {
        mainLinkedData.graph = faqBlocks.reduce((prev: any, faqBlock: FaqBlock) => {
            if (Array.isArray(faqBlock.content)) {
                return [
                    ...prev,
                    ...[{
                        type: "FAQPage",
                        name: faqBlock.title,
                        entries: faqBlock.content.reduce((prev1: any, group: FaqBlockEntry) => {
                            if (!!group.title && Array.isArray(group.text)) {
                                return [
                                    ...prev1,
                                    ...[
                                        {
                                            name: group.title,
                                            acceptedAnswer: group.text && group.text[0]
                                                ? group.text[0].children
                                                    .filter(child => child._type === "span")
                                                    .map(span => span.text)
                                                    .join("")
                                                : ""
                                        }
                                    ]
                                ];
                            }

                            return prev1;
                        }, [] as FaqBlockEntry[])
                    }]
                ];
            }

            return prev;
        }, [] as FaqBlock[]);
    }

    return (
        <Layout className="main" scrollMarkerRef={_heroEndMarkerRef}>
            <Seo
                image={mainImage?.asset.gatsbyImageData.images.fallback.src}
                title={title}
                description={description}
                postSlug={postSlug}
                linkedData={mainLinkedData} />
            <div className="post">
                <Image
                    className="mainImage"
                    src={mainImage?.asset.gatsbyImageData.images.fallback.src}
                    srcSet={mainImage?.asset.gatsbyImageData.images.fallback.srcSet}
                    sizes={mainImage?.asset.gatsbyImageData.images.fallback.sizes} />
                <ScrollMarker ref={_heroEndMarkerRef} />
                <h1 className="title">{title}</h1>
                <div className="tagInfo">
                    {!!tag && <Tag id={tag.tid} name={tag.name} />}
                    <ShareLinks
                        title={title}
                        url={`${siteUrl}/${postSlug}`} />
                </div>
                {
                    !!authorImage &&
                    authorName &&
                    (
                        <Author
                            className="postAuthor"
                            image={authorImage.asset.gatsbyImageData.images.fallback.src}
                            name={authorName}
                            publishedAt={publishedAt}
                            withContactLink
                            author={{
                                username: authorUsername,
                                email: authorEmail,
                                twitter: authorTwitter,
                                color: authorColor
                            }} />
                    )
                }
                <div className="postBody">
                    <TableOfContents h2Refs={h2Refs} items={tableOfContents} />
                    {elems}
                </div>
                <EndIcon />
                <div className="endLinks">
                    <ShareLinks
                        title={title}
                        url={`${siteUrl}/${postSlug}`} />
                </div>
            </div>
            {
                (data.otherPost1 || data.otherPost2) && (
                    <div className="linkedPosts">
                        {
                            data.otherPost1 && (
                                <Link key={data.otherPost1.slug.current} to={`/${data.otherPost1.slug.current}`}>
                                    <Card
                                        image={data.otherPost1.mainImage?.asset?.gatsbyImageData?.images?.fallback?.src}>
                                        <PostInfo
                                            tag={data.otherPost1.tag}
                                            title={data.otherPost1.title}
                                            description={data.otherPost1.description}
                                            author={(
                                                <AuthorLine
                                                    image={data.otherPost1.author
                                                        .image.asset.gatsbyImageData.images.fallback.src}
                                                    name={data.otherPost1.author.name}
                                                    publishedAt={data.otherPost1.publishedAt} />
                                            )} />
                                    </Card>
                                </Link>
                            )
                        }
                        {
                            data.otherPost2 && (
                                <Link key={data.otherPost2.slug.current} to={`/${data.otherPost2.slug.current}`}>
                                    <Card
                                        image={data.otherPost2.mainImage?.asset?.gatsbyImageData?.images?.fallback?.src}>
                                        <PostInfo
                                            tag={data.otherPost2.tag}
                                            title={data.otherPost2.title}
                                            description={data.otherPost2.description}
                                            author={(
                                                <AuthorLine
                                                    image={data.otherPost2.author.image.asset
                                                        .gatsbyImageData.images.fallback.src}
                                                    name={data.otherPost2.author.name}
                                                    publishedAt={data.otherPost2.publishedAt} />
                                            )} />
                                    </Card>
                                </Link>
                            )
                        }

                    </div>
                )
            }
            <div className="backButton">
                <a className="back" href="/">⃪ Go back to Zeplin Gazette</a>
            </div>
        </Layout>
    );
}

export const query = graphql`
  query($slug: String, $otherPostSlug1: String, $otherPostSlug2: String) {
    sanityPost(slug: {current: {eq: $slug}}) {
        ...Post
    }
    allSanityImageWithCaption {
        edges {
          node {
            ...ImageWithCaption
          }
        }
      }
      allSanityImageAsset {
        edges {
          node {
            ...Image
          }
        }
      }
    otherPost1: sanityPost(slug: {current: {eq: $otherPostSlug1}}) {
        ...LinkedPost
    }
    otherPost2: sanityPost(slug: {current: {eq: $otherPostSlug2}}) {
        ...LinkedPost
    }
    site {
        siteMetadata {
            siteUrl
        }
    }
  }
`;
